import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { gsap } from "gsap";
import { shuffle } from "lodash";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { Flip } from "gsap/Flip";
import { useWhyDidYouUpdate } from "ahooks";
import { pxToRem } from "../../utils/px-to-rem";
import { ProximityHover } from "../ProximityHover";
import { IPlanetHotspot, PlanetHotspot } from "../Jamunverse/PlanetHotspot";
import { Text } from "../Text";
import { PrevButton } from "../PrevButton";
import { NextButton } from "../NextButton";
import { JV_Z_INDICES } from "../Jamunverse/constants";
import { EncNavPath } from "../../assets/misc-svgs/components";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Styled = styled.div(() => ({
    display: "grid",
    gridAutoFlow: "column",
    gridGap: pxToRem(6),
    svg: {
        height: pxToRem(60)
    },
    img: {
        height: pxToRem(60)
    },
    ".space-letter": {
        width: pxToRem(20)
    },
    ".letter-wrapper": {
        position: "relative"
        // opacity: 0
    },
    ".white-letter": {
        opacity: 0.6
    },
    ".colored-letter": {
        opacity: 0,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    }
}));

export type ILetters = {
    el: React.ReactElement;
    type: "letter" | "space" | "break";
}[];

export interface IPilowlavaText {
    name: string;
    letters: ILetters;
    whiteLetters: ILetters;
    isActive?: boolean;
    showProgress?: number;
    body1: string;
    body2: string;
    initialHotspotX: number;
    initialHotspotY: number;
    onPrevClick?: () => void;
    onNextClick?: () => void;
    setActiveTl: (GSAPTimeline) => void;
    exitTimeout?: number;
    circleWidthPercentage?: number;
    textInitialScale?: number;
    svgPathOffsetXPercentage?: number;
    svgPathOffsetYPercentage?: number;
    onImagesLoaded?: () => void;
}

export const TO_ACTIVE_DURATION = 1.75;
export const TO_ACTIVE_EASE = "power3.out";

export const usePilowlavaTextActiveAnimation = () => {};

export const PilowlavaText = React.memo(
    ({
        name,
        letters,
        whiteLetters,
        isActive,
        showProgress = 0,
        body1,
        body2,
        circleWidthPercentage = 20,
        textInitialScale = 0.45,
        svgPathOffsetXPercentage = 0,
        svgPathOffsetYPercentage = 0,
        initialHotspotX,
        initialHotspotY,
        onNextClick = () => {},
        onPrevClick = () => {},
        setActiveTl = () => {},
        exitTimeout,
        onImagesLoaded = () => {}
    }: IPilowlavaText) => {
        const [imagesLoaded, setImagesLoaded] = useState(false);
        const containerRef = useRef<HTMLDivElement>(null);
        const [localIsActive, setLocalIsActive] = useState(false);
        const flipAnim = useRef<GSAPTimeline>(null);
        const motionPathAnim = useRef<GSAPTween>(null);
        const activeTl = useRef<GSAPTimeline>(null);

        useEffect(() => {
            const ctx = gsap.context(() => {
                Promise.all(
                    Array.from(gsap.utils.toArray<HTMLImageElement>("img"))
                        .filter((img) => !img.complete)
                        .map(
                            (img) =>
                                new Promise((resolve) => {
                                    img.onload = img.onerror = resolve;
                                })
                        )
                ).then(() => {
                    setImagesLoaded(true);
                    onImagesLoaded();
                });
            }, containerRef); // <- IMPORTANT! Scopes selector text

            return () => ctx.revert();
        }, []);
        // const [activeTl, setActiveTl] = useState(gsap.timeline({}));

        const [showLettersTl, setShowLettersTl] = useState(gsap.timeline({}));

        const lineBreakIndex = letters.findIndex(
            (letter) => letter.type === "break"
        );

        let letterLines;
        let whiteLetterLines;

        if (lineBreakIndex > -1) {
            letterLines = [
                letters.slice(0, lineBreakIndex),
                letters.slice(lineBreakIndex + 1, letters.length)
            ];
            whiteLetterLines = [
                whiteLetters.slice(0, lineBreakIndex),
                whiteLetters.slice(lineBreakIndex + 1, whiteLetters.length)
            ];
        } else {
            letterLines = [letters];
            whiteLetterLines = [whiteLetters];
        }

        useLayoutEffect(() => {
            gsap.registerPlugin(MotionPathPlugin, Flip);

            // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
            const ctx = gsap.context(() => {
                if (
                    imagesLoaded &&
                    (!activeTl.current ||
                        (activeTl.current &&
                            !(
                                activeTl.current.isActive() ||
                                activeTl.current.progress() === 1
                            )))
                ) {
                    if (initialHotspotX !== 0 && initialHotspotY !== 0) {
                        gsap.to(".letters-container", {
                            autoAlpha: 1
                        });

                        const splitArray = shuffle(
                            gsap.utils.toArray<HTMLDivElement>(
                                ".letter-type-wrapper"
                            )
                        );

                        const TOTAL_DURATION = 2;

                        motionPathAnim.current = gsap.to(splitArray, {
                            paused: true,
                            // reversed: direction === "anticlockwise",
                            immediateRender: true,
                            motionPath: {
                                path: ".text-path-svg path",
                                align: ".text-path-svg path",
                                alignOrigin: [0.5, 0.5]
                                // autoRotate: true
                            },
                            stagger: {
                                each: TOTAL_DURATION / splitArray.length,
                                repeat: -1,
                                ease: "none"
                            },
                            // transformOrigin: "50% 50%",
                            duration: TOTAL_DURATION,
                            ease: "none"
                        });
                        motionPathAnim.current.seek(TOTAL_DURATION);
                        // const containerBox = containerRef.current.getBoundingClientRect();
                        const initialState = Flip.getState(
                            ".letter-type-wrapper"
                        );

                        gsap.set(".letter-type-wrapper", {
                            clearProps: "all"
                        });

                        flipAnim.current = Flip.from(initialState, {
                            paused: true,
                            ease: TO_ACTIVE_EASE,
                            duration: TO_ACTIVE_DURATION
                        });

                        activeTl.current = gsap
                            .timeline({
                                data: {
                                    instanceof: "PilowlavaText"
                                },
                                paused: true,
                                defaults: {
                                    ease: TO_ACTIVE_EASE,
                                    duration: TO_ACTIVE_DURATION
                                    // overwrite: true
                                }
                            })
                            .add(flipAnim.current.play(), "=")
                            .to(".colored-letter", { autoAlpha: 1 }, "=")
                            .to(".white-letter", { autoAlpha: 0 }, "=")
                            .from(
                                ".letter-type-wrapper",
                                {
                                    scale: textInitialScale
                                },
                                "="
                            )
                            .fromTo(
                                ".body",
                                {
                                    clipPath: "inset(0% 0% 100% 0%)"
                                    // maxHeight: 0
                                },
                                {
                                    id: "PilowlavaTextBody",
                                    data: {
                                        instanceof: "PilowlavaTextBody"
                                    },
                                    duration: 1,
                                    // maxHeight: 9999,
                                    clipPath: "inset(0% 0% 0% 0%)"
                                }
                            );
                        // );

                        setShowLettersTl(
                            gsap.timeline({ paused: true }).fromTo(
                                containerRef.current,
                                { autoAlpha: 0 },
                                {
                                    autoAlpha: 1,
                                    ease: "power3.in"
                                }
                            )
                        );
                    }
                }
            }, containerRef); // <- IMPORTANT! Scopes selector text
            return () => ctx.revert(); // cleanup
        }, [initialHotspotY, initialHotspotX, imagesLoaded, textInitialScale]);

        useLayoutEffect(() => {
            if (
                activeTl.current &&
                !(
                    activeTl.current.isActive() ||
                    activeTl.current.progress() === 1
                )
            ) {
                setActiveTl(activeTl.current);
            }
        }, [activeTl.current]);

        // useWhyDidYouUpdate("PilowlavaText", {
        //     initialHotspotY,
        //     initialHotspotX,
        //     imagesLoaded,
        //     textInitialScale
        // });

        // useEffect(() => {
        //     if (isActive) {
        //         activeTl.play();
        //     } else {
        //         activeTl.reverse();
        //     }
        // }, [isActive]);

        useLayoutEffect(() => {
            if (isActive) {
                setLocalIsActive(true);
            } else
                setTimeout(() => {
                    setLocalIsActive(false);
                }, exitTimeout);
        }, [isActive, exitTimeout]);

        useLayoutEffect(() => {
            if (!localIsActive) {
                // if (showProgress > 0) {
                gsap.to(showLettersTl, {
                    progress: showProgress,
                    overwrite: true,
                    duration: 0.71
                });
                // }
            } else {
                showLettersTl.progress(1);
            }
        }, [showProgress, localIsActive]);

        return (
            <div
                id={`${name}-pilowlava-text-container`}
                className="pilowlava-text-container"
                css={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: "flex",
                    zIndex: JV_Z_INDICES.hotspotsText,
                    alignItems: "center"
                }}
            >
                <div
                    className="pilowlava-text"
                    ref={containerRef}
                    css={{
                        width: "40vw",
                        maxWidth: "400px",
                        marginLeft: "55vw",
                        display: "grid",
                        gridGap: pxToRem(24),
                        justifyContent: "start",
                        justifyItems: "start",
                        alignItems: "start"
                    }}
                >
                    <EncNavPath
                        className="text-path-svg"
                        css={{
                            position: "absolute",
                            left: `calc(${initialHotspotX}px - ${
                                circleWidthPercentage / 2
                            }vw + ${svgPathOffsetXPercentage}%)`,
                            top: `calc(${initialHotspotY}px - ${
                                circleWidthPercentage / 2
                            }vw + ${svgPathOffsetYPercentage}%)`,
                            // position: "absolute",
                            width: `${circleWidthPercentage}vw`,
                            // height: "100%",
                            path: {
                                opacity: 0
                            }
                        }}
                    />
                    <div
                        className="letters-container"
                        css={{
                            opacity: 0,
                            // width: "40vw",
                            width: "100%",
                            display: "grid",
                            gridGap: pxToRem(12),
                            justifyContent: "start",
                            justifyItems: "start"
                        }}
                    >
                        {letterLines.map((line, lineIndex) => (
                            <div className="line-container" css={{}}>
                                <Styled
                                    css={{}}
                                    className={`line line-${lineIndex}`}
                                >
                                    <>
                                        {line.map((letter, index) => {
                                            return (
                                                <div
                                                    css={{
                                                        willChange: "transform",
                                                        marginRight: pxToRem(-6),
                                                        marginBottom: pxToRem(-6),
                                                    }}
                                                    className={`letter-wrapper letter-${
                                                        index + 1
                                                    } ${
                                                        letter.type
                                                    }-type-wrapper`}
                                                >
                                                    <div
                                                        css={{
                                                            willChange:
                                                                "transform"
                                                        }}
                                                        className="colored-letter"
                                                    >
                                                        {letter.el}
                                                    </div>
                                                    <div
                                                        css={{
                                                            willChange:
                                                                "transform"
                                                        }}
                                                        className="white-letter"
                                                    >
                                                        {
                                                            whiteLetterLines[
                                                                lineIndex
                                                            ][index].el
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                </Styled>
                            </div>
                        ))}
                    </div>
                    <div
                        className="body"
                        css={{
                            clipPath: "inset(0% 0% 100% 0%)",
                            // maxHeight: 0,
                            overflow: "hidden",
                            display: "grid",
                            gridGap: pxToRem(64)
                        }}
                    >
                        <Text variant="caption" color="white">
                            {body1}
                        </Text>
                        <div>
                            <hr />
                            <Text
                                css={{
                                    marginTop: pxToRem(20)
                                }}
                                variant="smallCaption"
                                color="white"
                            >
                                {body2}
                            </Text>
                            <div
                                css={{
                                    marginTop: pxToRem(32),
                                    display: "grid",
                                    gridAutoFlow: "column",
                                    gridGap: pxToRem(32),
                                    alignItems: "center",
                                    justifyContent: "start"
                                }}
                            >
                                <PrevButton onClick={onPrevClick} />
                                <NextButton onClick={onNextClick} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export const TextWithHotspot = ({
    children,
    onClick,
    proximityDisabled,
    top,
    left,
    initialScale,
    hotspotPositionOnly
}: {
    children: ({
        progress,
        initialHotspotX,
        initialHotspotY
    }: {
        progress: number;
        initialHotspotX: number;
        initialHotspotY: number;
    }) => JSX.Element;
    proximityDisabled?: boolean;
    hotspotPositionOnly: {
        top?: number;
        left?: number;
    };
} & IPlanetHotspot) => {
    const [progress, setProgress] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        ScrollTrigger.observe({
            target: window, // can be any element (selector text is fine)
            type: "wheel,touch", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
            onChange: () => {
                setProgress(0);
            }
        });
    }, []);

    // const memoizedChildren = useCallback(children, [children]);
    // useWhyDidYouUpdate("PText", {
    //     isActive,
    //     progress,
    //     initialHotspotX,
    //     initialHotspotY,
    //     onClick,
    //     proximityDisabled,
    //     memoizedChildren
    // });

    return (
        <div>
            <div
                css={{
                    position: "absolute",
                    zIndex: JV_Z_INDICES.hotspots,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    top: hotspotPositionOnly.top,
                    left: hotspotPositionOnly.left
                }}
                className="text-with-hotspot"
                ref={containerRef}
            >
                <ProximityHover
                    onUpdate={setProgress}
                    maxDistance={250}
                    bufferFromCenter={10}
                    disabled={proximityDisabled || !top || !left}
                >
                    <PlanetHotspot
                        initialScale={initialScale}
                        // top={top}
                        // bottom={bottom}
                        // left={left}
                        // right={right}
                        onClick={() => {
                            if (onClick) onClick();
                        }}
                    />
                </ProximityHover>
            </div>
            <div
                css={
                    {
                        // position: "absolute",
                        // left: "50%",
                        // // marginLeft: "-50%",
                        // zIndex: "-1",
                        // top: "50%",
                        // transform: `translate(-50%, ${translateY}%)`
                        // marginTop: "-50%"
                    }
                }
            >
                {children({
                    progress,
                    initialHotspotX: left,
                    initialHotspotY: top
                })}
            </div>
        </div>
    );
};
